.animate-listing {
    margin-top: 50px;

}
.animate-listing div a {
    font-size: 2.125rem;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 30px;
    display: inline-block;
    line-height: 2.688rem;
}


.animate-listing div a.active {
    color: rgba(255, 255, 255, 1);
}

.os-scrollbar-track {
    background: #A0AAF0 !important;
}

.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle,
.os-scrollbar-handle {
    background: #EB5D2D !Important;
}

.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
    min-height: 60px;
    max-height: 60px;
}

.os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
    min-width: 30px;
    max-width: 30px;
}

/* .animate-listing-rt .os-viewport{
    padding-left: 30px !important;
} */

/* .animate-listing-lt .os-viewport {
    padding-right: 30px !important;
} */

.animate-listing-rt .os-scrollbar-vertical {
    left: 0 !important;
}
.animate-listing-lt div a{
    position: relative;
    padding-right: 20px;
}

.animate-listing-lt div a::before {
    content: "";
    background: #EB5D2D !important;
    width: 4px;
    height: 100%;
    right: 0;
    position: absolute;
    border-radius: 10px;
    top: 0;
    opacity: 0;
    /* transform: translateY(-50%); */
}

.animate-listing-rt div a{
    position: relative;
    padding-left: 20px;
}

.animate-listing-rt div a::before {
    content: "";
    background: #EB5D2D !important;
    width: 4px;
    height: 100%;
    left: 0;
    position: absolute;
    border-radius: 10px;
    top: 0;
    opacity: 0;
    /* transform: translateY(-50%); */
}
.animate-listing-lt div a.active::before, .animate-listing-rt div a.active::before{
    opacity: 1; 
} 

/* DOWNLOAD CALL ACTION */

.call-action img {
    width: 150px;
    margin: 20px auto 40px auto;
}

.btn-lg {
    max-width: 350px;
    width: 100%;
}


.privacy-content {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* MOBILE app carousel */

.app-media__slides img{
    display: block;
    margin: 0 auto;
}

.slick-arrow::before{
    display: none;
}

.slick-next, .slick-prev{
    width: auto;
    height: auto;
    z-index: 3;
}

.slick-prev{
    left: 0;
}

.slick-prev svg{
    transform: rotate(180deg);
}

.slick-next{
    right: 0;
}

.symptoms  .slick-disabled{
    display: none !important;
}


/* Mean list */

.mean-list{
    display: flex;
    flex-direction: column;
}

.mean-list span {
color:var(--text-primary-color);
margin: 10px 0;
}

.mean-text{
    padding-top: 135px;
}


@media (max-width:767px){

    .animate-listing {
        margin-top: 30px;
    }

    .animate-listing div a{
        font-size: 1.25rem !important;
    line-height: 1.5rem !important;
    margin-bottom: 15px;
    pointer-events: none;
    }

    .app-media__slides img{
      max-width: 300px;
    }
    .slider-text .slick-slide > div{
        padding-left: 30px;
    }

}

@media (max-width:568px){
    .app-media__slides img{
        max-width: 180px;
          
      }
}




