.Symptoms-content p {
    max-width: 600px;
    margin: 0 auto;
}


.app-slider-image img{
    display: block;
    margin: 50px auto;
    max-width: 300px;

}

