
.react-cookie-law-container {
	max-width: 100% !important;
    padding-right: 40%;
}

.react-cookie-law-msg, .react-cookie-law-dialog label, .react-cookie-law-policy{
    color: #fff !important;
}

.react-cookie-law-msg{
font-size: 1.25rem !important;
}
.react-cookie-law-policy{
    text-decoration: underline !important;
}

.react-cookie-law-accept-all-btn, .react-cookie-law-accept-selection-btn {
    background:transparent !important;
    border: 2px solid #FFFFFF;
    border-radius: 30px;
    width: 200px;
    height: 55px;
    font-size: 1rem !important;
}
.react-cookie-law-accept-all-btn{
    border-color: #808CE3;
background: linear-gradient(to right, rgba(0, 166, 226, 1) 0%, rgba(128, 140, 227, 1) 40%, rgba(192, 76, 134, 1) 71%, rgba(235, 93, 45, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a6e2', endColorstr='#eb5d2d', GradientType=1);
}
.react-cookie-law-accept-all-btn:hover{
    background:linear-gradient(to right, rgba(107, 124, 252, 0.7) 0%, rgba(107, 124, 252, 0.75) 17%, rgba(252, 6, 131, 0.86) 53%, rgba(255, 3, 129, 0.94) 79%, rgba(255, 84, 8, 1) 100%) !important;
}
.react-cookie-law-accept-selection-btn:hover{
    border-color: #808CE3;
    background: linear-gradient(to right, rgba(0, 166, 226, 1) 0%, rgba(128, 140, 227, 1) 40%, rgba(192, 76, 134, 1) 71%, rgba(235, 93, 45, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a6e2', endColorstr='#eb5d2d', GradientType=1);
}

.react-cookie-law-button-wrapper {
	float: right;
	position: absolute;
	right: 0;
	top: 50%;
    transform: translateY(-50%);
	text-align: right;
}

.react-cookie-law-select-pane{
    display: none !important;
}

.react-cookie-law-policy{
margin-left: 0px !important;
}

img.logo-footer {
    max-width: 499px;
}

@media (max-width:991px){
    .react-cookie-law-container {
        max-width: 100% !important;
        padding-right: 0;
        text-align: center;
    }

    .react-cookie-law-msg {
        font-size: 1rem !important;
    }

    img.logo-footer {
        max-width: 350px;
    }

    .react-cookie-law-button-wrapper {
        position: static;
        transform: none;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    /* .react-cookie-law-select-pane{
        display: flex !important;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center !important;
    } */
}