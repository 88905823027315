.heading-font-24{
  font-size: 1.5rem !important;
}

.opacity0,
.about-hero__subheading {
  opacity: 0;
}


/* Research */
.research{
background-color: #1D276C;
}

.research p, .founder p, .team__info__descp p{
  line-height: 24px;
}

.research_blk p, .team__info__descp p{
  margin-bottom: 10px;
}


/* FOUNDER */

.founder-headshot{
  max-width: 180px;
  margin: 30px auto;
}

.founder-bio .news-readmore span svg{
    transform: rotate(180deg);
    transform-origin: 49% 47%;
}

.founder-bio p{
  font-size: 1.25rem;
  line-height: 1.688rem;
}

.founder-bio .back-link{
  margin-top: 80px;
}

/* mission */

.about-mission span{
  margin-bottom: 15px;
}

/* team */

.team__info {
	margin-top: 20px;
}

.team__info__descp {
	margin-top: 10px;
}

/* Testimonials */

.testimonials-shadow::before{

  height: 550px;
  background: radial-gradient(ellipse at 49% 0%, rgba(0, 100, 154, 1) 0%, rgba(0, 100, 154, 1) 34%, rgba(3, 20, 59, 1) 67%, rgba(3, 20, 59, 1) 100%);
}

.testimonials .slick-dots button{
  width: 12px;
  height: 12px;
  border: 1px solid #707070;
  border-radius: 50%;
}
.testimonials .slick-dots button::before{
  opacity: 0 !important;
}
.testimonials .slick-dots .slick-active button{
  background-color: #FFFFFF;
  border: 1px solid #fff;
}

.testimonials .slick-dots{
  position: static;
  margin-top: 40px;
}


@media (max-width: 991px) {
  .about-hero h1 {
    margin-bottom: 50px !important;
  }
}

@media (max-width: 767px) {
  body .heared-listing ul.h4 {
    font-size: 1.25rem !important;
    line-height: 1.875rem !important;
  }
}


@media (max-width:576px) and (min-width:375px){
  .testimonials-slide .h4{
    font-size: 1.375rem !important;
  }

  .testimonials-slide .w-75.h5{
    font-size: 1rem !important;
  }
}
