.hero,
.about-hero {
    height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.home-hero{
    padding-top: 150px;
    overflow-x: hidden;
    height: auto !important;
}

.overflow-x-hidden{
    overflow-x: hidden;
}

.hero:before,
.about-hero::before, .hero-shadow::before {
    width: 100%;
    height: 500px;
    position: absolute;
    top: -100px;
    left: 0;
    content: "";
    background: -moz-radial-gradient(ellipse at 49% 0%, rgba(7, 0, 183, 1) 0%, rgba(5, 0, 137, 1) 34%, rgba(3, 20, 59, 1) 67%, rgba(3, 20, 59, 1) 100%);
    background: -webkit-radial-gradient(ellipse at 49% 0%, rgba(7, 0, 183, 1) 0%, rgba(5, 0, 137, 1) 34%, rgba(3, 20, 59, 1) 67%, rgba(3, 20, 59, 1) 100%);
    background: -o-radial-gradient(ellipse at 49% 0%, rgba(7, 0, 183, 1) 0%, rgba(5, 0, 137, 1) 34%, rgba(3, 20, 59, 1) 67%, rgba(3, 20, 59, 1) 100%);
    background: -ms-radial-gradient(ellipse at 49% 0%, rgba(7, 0, 183, 1) 0%, rgba(5, 0, 137, 1) 34%, rgba(3, 20, 59, 1) 67%, rgba(3, 20, 59, 1) 100%);
    background: radial-gradient(ellipse at 49% 0%, rgba(7, 0, 183, 1) 0%, rgba(5, 0, 137, 1) 34%, rgba(3, 20, 59, 1) 67%, rgba(3, 20, 59, 1) 100%);

}

.hero__heading__two {
    margin-left: 28%;
}

.hero__heading__three {
    margin-left: 15%;
}
/* .introducing {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 3;
    display: none;
} */

.introducing {
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 3;
    display: none;
}

.introducing img{
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
}

.introducing .mouse-scroll{
    margin-top: 20px;
}

.logo-animate svg{
transform: translate3d(0, 0 , 0);
}

.hero__logo{
    display: block;
    max-width: 230px;
    margin-left: auto;
    margin-right: auto;
}


@media(max-width:767px){
    .home-hero{
        padding-top: 150px;
        height: auto !important;
    }
}


@media (max-width:767px) {
    .hero__heading__two, .hero__heading__three{
        margin-left: 0px !important;
    }
    .home-hero h1{
        text-align: center;
    }
}

@media (max-width:567px) {
    .h3.hero__heading__two{
        font-size: 1.75rem !important;
    }

}

@media (max-width: 374px)
   {
    body section#big-letter-u h1, body section#big-letter-u .h1{
    font-size: 40px !important;
    font-weight: 600;
    line-height: 40px;
}

body section#big-letter-u h4, body section#big-letter-u .h4{
   font-size: 18px !important;
   line-height: 24px !important;
 }
 .home-hero h1 {
    text-align: center;
    margin-bottom: 5px !important;
}
}