:root{
    --spacer-sm:70px;
    --spacer-xs:40px;
    }

    .spacer-sm-top{
        padding-top: var(--spacer-sm);
    }

    .spacer-sm-bottom{
        padding-bottom: var(--spacer-sm);
    }

    .spacer-sm{
        padding: var(--spacer-sm) 0px;
    }
    .spacer-xs{
        padding: var(--spacer-xs) 0px;
    }
    .purple-shadow::before{
        
    /* ff 3.6+ */
    background:-moz-radial-gradient(ellipse at 50% 52%, rgba(75, 13, 186, 1) 3%, rgba(1, 19, 57, 1) 74%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(ellipse at 50% 52%, rgba(75, 13, 186, 1) 3%, rgba(1, 19, 57, 1) 74%);

    /* opera 11.10+ */
    background:-o-radial-gradient(ellipse at 50% 52%, rgba(75, 13, 186, 1) 3%, rgba(1, 19, 57, 1) 74%);

    /* ie 10+ */
    background:-ms-radial-gradient(ellipse at 50% 52%, rgba(75, 13, 186, 1) 3%, rgba(1, 19, 57, 1) 74%);

    /* global 92%+ browsers support */
    background:radial-gradient(ellipse at 50% 52%, rgba(75, 13, 186, 1) 3%, rgba(1, 19, 57, 1) 74%);

}
    
.news-info{
    line-height: 0px;
}

.news-info span {
	border-right: 2px solid #fff;
	font-size: 14px;
	line-height: 14px !important;
	padding: 0;
	margin: 0;
	padding-right: 10px;
	padding-left: 10px;
}

.news-info span:last-child{
    border-right: 0px;
}

.news-listing .col-md-4{
    margin-bottom: 55px;
    transition: transform .15s linear;
}

.news-listing .col-md-4:hover{
 transform: scale(1.1);   
}

.news-listing{
    margin-bottom: -40px;
}

.news-listing__blk a:hover{
    color: var(--text-purple) !important;
}

.news-readmore:hover span{
    background-color:var(--text-purple);
}

.more-news  .slick-list{
    overflow: visible;
}

/* .more-news__slider .slick-list{
    padding-left: calc(50% - 565px);

} */

/* .slick-padding{
    padding-left:0px !important;
}  */

.more-news .slick-slide > div{
    margin: 0 10px !important;
}

.content-spacer{
    margin-top: 30px;
}

.news-detail p, .news-detail li {
    font-size: 1.2rem;
    padding-top: .4rem;
}
.news-detail h4 {
    padding: 1.2rem 0 .4rem;
}