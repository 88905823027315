.steps {
    /* margin-top: 400px; */
    /* padding-top: 100px; */
}

.steps__content {
    max-width: 537px;
    width: 100%;
    position: absolute;
}

.steps__heading {
    font-size: 1.125rem;
}

.steps__subheading {
    fill: #808CE3;
    font-size: 1rem;
}

.steps__one__graphics {
    position: relative;
}

#Scroll_Group_1 {
    stroke-opacity: 0.5;
    stroke-dasharray: 3500;
    stroke-dashoffset: 3500;
}

/* LATEST STEPS */

.latest-steps .steps__content {
	max-width: 55%;
	left: 0;
    top: 100px;
}
.latest-steps .steps__content h2 {
    font-size: 34px !important;
    line-height: 40px !important;
}
.main-txt{
    color: #C12EC1;
}

@media (min-width:768px){
    .mob-steps{
      display: none;
   }
 }


 @media (max-width:767px){
    .tf-v1-sidetab-button {
        left: -33px !important;
        height: 35px !important;
    }
    .tf-v1-sidetab-button-icon {
        /*display: none;*/
    }
    .desktop-steps{
        display: none;
    }
    .quote {
        font-size: 0.8em;
        text-align: right;
        font-style: italic;
    }
    .latest-steps .steps__content {
        max-width: 100%;
        left: 0;
        top: 100px;
    }
 }
