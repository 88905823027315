:root {
    --primary-font: 'Nunito', sans-serif;
    --secondary-font: 'Covered By Your Grace', cursive;
    --bg-primary: #03143B;
    --bg-secondary: #808CE3;
    --bg-tertiary: #C8CEF7;
    --bg-darkblue: #5662D9;
    --bg-black: #000;
    --text-primary-color: #13A5DF;
    --text-secondary-color: #EB5D2D;
    --text-purple: #C12EC1;
    --grey: #C12EC1;
    --red: #da2c4d;
	--yellow: #f8ab37;
    --spacer:120px;
    --bg-yellow:#FF9300;
    --bg-green:#4AC12E;
    --bg-blue:#11A4DE;
    --bg-purple:#645BE6;
    --bg-pink:#C12EC1;
    --bg-red:#C12E2E;
}

html , html:focus-within{
    scroll-behavior: auto !important;
    -webkit-scroll-behavior: auto !important;
   }

html,
body {
    height: 100%;
}
 
body {
    font-family: var(--primary-font) !important;
    font-weight: 600 !important;
    background-color: var(--bg-primary) !important;
    color: #fff;
    overflow-x: hidden;
    font-size: 18px;
}

form.uk-form input {
    background: #EFF5FD 0% 0% no-repeat padding-box;
    border: 1px solid #BCBCBC;
    border-radius: 10px;
    opacity: 1;
    display: block;
    width: 100%;
    height: 65px;
    margin: 20px 0;
    padding: 0 20px;
    font-style: italic;
}
form.uk-form button {
    width: 100%;
}
.video .store-links {
    justify-content: center;
}
.mt-neg-1 {
    margin-top: -1em !important;
}
.mt-neg-2 {
    margin-top: -2em !important;
}
.mt-neg-3 {
    margin-top: -3em !important;
}

.bg-yellow{
    background-color: var(--bg-yellow);
}
.bg-green{
    background-color: var(--bg-green);
}
.bg-blue{
    background-color: var(--bg-blue);
}
.bg-darkblue{
    background-color: var(--bg-darkblue);
}
.bg-purple{
    background-color: var(--bg-purple);
}
.bg-pink{
    background-color: var(--bg-pink);
}
.bg-red{
    background-color: var(--bg-red);
}

img {
    max-width: 100%;
}

.container-default{
    max-width: 1140px !important;
}

.container-sm {
    max-width: 991px !important;
}

.btn {
    color: #fff;
    z-index: 1;
    position: relative;
    min-width: 230px;
    border: none;
    border-radius: 30px;
    padding: 12px 20px;
    font-size: 1.25rem;
    overflow: hidden;
    font-weight: 600;
    background: rgb(0, 166, 226);
    background: -moz-linear-gradient(left, rgba(0, 166, 226, 1) 0%, rgba(128, 140, 227, 1) 40%, rgba(192, 76, 134, 1) 71%, rgba(235, 93, 45, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(0, 166, 226, 1) 0%, rgba(128, 140, 227, 1) 40%, rgba(192, 76, 134, 1) 71%, rgba(235, 93, 45, 1) 100%);
    background: linear-gradient(to right, rgba(0, 166, 226, 1) 0%, rgba(128, 140, 227, 1) 40%, rgba(192, 76, 134, 1) 71%, rgba(235, 93, 45, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a6e2', endColorstr='#eb5d2d', GradientType=1);
}

.btn::before{
    width: 100%;
    height: 100%;
    content: "";
    top: 0px;
    left: 0px;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all .30s ease-in-out;
    background: -moz-linear-gradient(left, rgba(107, 124, 252, 0.7) 0%, rgba(107, 124, 252, 0.75) 17%, rgba(252, 6, 131, 0.86) 53%, rgba(255, 3, 129, 0.94) 79%, rgba(255, 84, 8, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(107, 124, 252, 0.7) 0%, rgba(107, 124, 252, 0.75) 17%, rgba(252, 6, 131, 0.86) 53%, rgba(255, 3, 129, 0.94) 79%, rgba(255, 84, 8, 1) 100%);
    background: linear-gradient(to right, rgba(107, 124, 252, 0.7) 0%, rgba(107, 124, 252, 0.75) 17%, rgba(252, 6, 131, 0.86) 53%, rgba(255, 3, 129, 0.94) 79%, rgba(255, 84, 8, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b36b7cfc', endColorstr='#ff5408', GradientType=1);
   
}

.btn:hover::before, .last-item a:hover::before {
    opacity: 1 !important;
    visibility: visible !important;
}

.bg-secondary {
    background: var(--bg-secondary) !important;
}

.secondary-font {
    font-family: var(--secondary-font);
}


.primary-text-color {
    color: var(--text-primary-color) !important;
}

.secondary-text-color {
    color: var(--text-secondary-color);
}

.text-purple {
    color: var(--text-purple) !important;
}

.text-yellow{
    color: var(--bg-yellow) !important;
}

.text-green{
    color: var(--bg-green) !important;
}

.text-blue{
    color: var(--bg-blue) !important;
}

.text-pink{
    color: var(--bg-pink) !important;
}

.text-red{
    color: var(--bg-red) !important;
}

.text-purplee{
    color: var(--bg-purple) !important;
}


h1,
.h1 {
    font-size: 5.625rem !important;
    font-weight: 600;
    line-height: 5.625rem;
}

h2,
.h2 {
    /* font-size: 3.125rem !important; */
    font-size: 4.375rem !important;
    line-height: 4.563rem;
}

h3,
.h3 {
    font-size: 3.125rem !important;
    line-height: 3.813rem !important;
    /* font-size:1.875rem !important; */
}

h4,
.h4 {
    font-size: 2rem !important;
    line-height: 2.625rem !important;
}

h5,
.h5 {
    line-height: 1.688rem;
}

h6,
.h6 {
    font-size: 1.125rem !important;
}

a,
a:link {
    text-decoration: none !important;
    color: #fff;
}

.full-screen {
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 100px 0;
}

.spacer-top{
    padding-top:var(--spacer);
}

.spacer-bottom{
    padding-bottom:var(--spacer);
}

.spacer{
    padding:var(--spacer) 0;
}

p{
    line-height: 30px;
}

body .text-decoration-underline{
    text-decoration: underline !important;
}

.hover-color:hover{
    color: var(--text-purple) !important;
}
/* section{
    overflow-x: hidden;
} */


 /* width */

 :root{
    scrollbar-color: #EB5D2D rgba(238,238,238,0.7) !important;
    scrollbar-width: 15px !important;
  }

 ::-webkit-scrollbar {
    width: 15px;
    }
    
    ::-webkit-scrollbar-track {
    background: rgba(238,238,238,0.7)
    }
    
    
    ::-webkit-scrollbar-thumb {
    background: #EB5D2D ;
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: #EB5D2D;
    } 


    

@media(max-width:991px){
 
  .container-sm, .container, .container-default{
    max-width: 92% !important;
  }



    /* TYPOGRAPHY */
    
    h1, 
.h1 {
    font-size: 3.125rem !important; 
    font-weight: 600;
    line-height: 3.125rem !important;
}

h2,
.h2 {
    font-size: 2.3rem !important;
    line-height: 3.125rem !important;
}

h3,
.h3 {
    font-size: 2.188rem !important;
    line-height: 2.813rem !important;
    /* font-size:1.875rem !important; */
}

h4,
.h4 {
    font-size: 1.75rem !important;
    line-height: 2.375rem !important;
}

h5,
.h5 {
    line-height: 1.688rem;
}

h6,
.h6 {
    font-size: 1.125rem !important;
}

}

@media (max-width:767px){
    .mob-width{
        width: 100% !important;
      }

      .Symptoms-content p{
        max-width: 100% !important;
      }
}

@media (max-width:374px)
{
h1, 
.h1 {
    font-size: 2.5rem !important; 
    font-weight: 600;
}

h2,
.h2 {
    font-size: 2rem !important;
}

h3,
.h3 {
    font-size: 1.75rem !important;

    /* font-size:1.875rem !important; */
}

h4,
.h4 {
    font-size: 1.25rem !important;
 
}


}

