@import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap');

.thumbup h3 {
    margin-top: 60px;
}

/* body{
    scroll-behavior: smooth;
} */
/* MOUSE POINTER */

.mouse-scroll {
    margin-top: 135px;
    display: inline-block;
    cursor: pointer;
}

.mouse-scroll__position{
    display: inline-block;
     position: absolute;
    bottom: 20px;
    left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.mouse-scroll:hover path,
.mouse-scroll:hover #Rectangle_547 {
    stroke: url(#linear-gradient);
    transition: all .15s ease-in-out;
}


/* ABOUT US */

.hero-section-spacer{
    padding-top: 150px;
}

.about-hero {
    padding-top: 150px !important;
}

.about-hero h1 {
    margin-bottom: 100px;
    line-height: 61px;
}

.heared-listing ul > li:not(:last-child) {
    margin-bottom: 30px;
}

.quote__content p {
    max-width: 600px;
    margin: 0 auto;
    color: #D1D7FF;
}

.quote__content .quoter-info {
    text-align: left;
    margin-top: 60px;
}

.quote__content .quoter-info .quoter {
    font-weight: bold;
}

.quote__content img {
    position: absolute;
    top: 0;
}

.quote-left {
    left: 0;
}

.quote-right {
    right: 0;
}

.goal-points span {
    margin-top: 35px;
}


.explainer-video {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    width: -moz-calc(100% - 85px);
    width: -webkit-calc(100% - 85px);
    width: calc(100% - 85px);
    margin: 0 auto;
    overflow: hidden;

}

.explainer-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: -moz-calc(100% - 85px);
    height: -webkit-calc(100% - 85px);
    height: calc(100% - 85px);
}

.line {
    position: relative;
    /* overflow: hidden; */
    will-change: transform;
}

.hand-img{
    animation: handshake .15s ease-in infinite;
    animation-direction: alternate;
}


@keyframes handshake {
 0%{
    transform: rotate(0deg);
 }

 100%{
    transform: rotate(6deg);
 }
    
}

/* MEDIA QUERY START */

@media (max-width:991px){
    .quote__content img{
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .quote-left{
     top: 0;
    }
    .quote-right{
    bottom: 0 !important;
    top: inherit !important;
   }
   .quote__content {
	max-width: 100%;
	padding-top: 130px;
	padding-bottom: 130px;
}
}

@media (max-width:767px){
    .Symptoms-content .mouse-scroll__position{
            position: static !important;
            transform: none !important;
    }
      
    .Symptoms-content{
        padding-top: var(--spacer);
        height: auto;
    }

    #symptoms-sign{
        padding-bottom: 0px !important;
    }
  
    .citizen .col-md-7 img{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .citizen{
        text-align: center;
    }

}

@media (max-width:567px){

    /* EXPLIANER PAGE */
    .explainer-video{
     padding-top: 0 !important;
     width: -moz-calc(100% - 30px) !important;
     width: -webkit-calc(100% - 30px) !important;
     width: calc(100% - 30px) !important;
    }
    .explainer-video iframe{
     position: relative !important;   
 }
 }
 .mb-10p{
    margin-bottom: 10px !important;
 }
 .mt-20p{
    margin-top: 20px !important;
 }

/*
 .nav__submenu {
	 display: none;
}
 .nav__menu-item:hover .nav__submenu {
	 display: block;
}

 .menu-container {
	 text-align: center;
}
 nav ul {
	 list-style: none;
	 padding-left: 0;
	 margin-top: 0;
	 margin-bottom: 0;
}
 .nav {
	 display: inline-block;
	 margin: 2rem auto 0;
	 background: #0d2035;
	 color: #fff;
	 text-align: left;
}
 .nav a {
	 display: block;
	 padding: 0 16px;
	 line-height: inherit;
	 cursor: pointer;
}
 .nav__menu {
	 line-height: 45px;
	 font-weight: 700;
	 text-transform: uppercase;
}
 .nav__menu-item {
	 display: inline-block;
	 position: relative;
}
 .nav__menu-item:hover {
	
}
 .nav__menu-item:hover .nav__submenu {
	 display: block;
}
 .nav__submenu {
	 font-weight: 300;
	 text-transform: none;
	 display: none;
	 position: absolute;
	 width: 180px;

}
 .nav__submenu-item:hover {
	 background: rgba(0, 0, 0, 0.1);
}
 */