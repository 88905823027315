.typing-txt {
    /* transform: rotate(-6deg); */
    /* min-height: 400px; */
}

.typing-txt div{
    font-family: 'Covered By Your Grace', cursive;
    color: #C12EC1;
    font-size: 3.125rem;
    min-height: 80px;
}


@media(max-width:767px){
    .typing-txt div{
        font-family: 'Covered By Your Grace', cursive;
        color: #C12EC1;
        font-size: 1.75rem;
        min-height: 50px;
        line-height: 2.625rem !important;
        margin-bottom: 15px;
    }

    .typing-txt div:last-child{
        margin-bottom: 0;
    }

}

@media(max-width:550px){
    .typing-txt div{
    font-size: 1.125rem;
    min-height: auto;
    line-height: 1.5rem !important;
    margin-bottom: 15px;
    }
}

@media(max-width:375px){
    .typing-txt div{
    font-size: 1rem;
    min-height: auto;
    line-height: 1.5rem !important;
    margin-bottom: 15px;
    }
}