/* faq */

.faq__headings {
    margin-bottom: 150px;
}

.accordion-item {
    color: #fff;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #D1D7FF;
    padding-top: 50px;
    padding-bottom: 20px;
    border-radius: 0px !important;
    position: relative;
}

.accordion-button {
    background: transparent !important;
    color: var(--text-primary-color) !important;
    font-size: 1.125rem !important;
    padding: 0 !important;
    box-shadow: none !important;
    /*    position: static;*/
    flex-wrap: wrap;
    align-items: flex-start;

}

.accordion-body {
    color: #fff;
    padding-left: 0;
}

.accordion-body ul {
    list-style: none;
    padding: 0 0 20px;
    margin: 0;
}

.accordion-body ul li {
    line-height: 23px;
    margin-bottom: 10px;
    margin-left: 20px;
    list-style: disc;
}

.accordion-body ul li:last-child {
    /*margin-bottom: 0;*/
}

.faq__icon {
    width: 100px;
}

.faq__label {
    width: calc(100% - 100px);
    padding-left: 35px;
    padding-right: 95px;
}

.faq__icon span {
    color: #fff;
    font-weight: 400 !important;
    display: block;
    margin-top: 8px;
}

.faq .accordion-button:not(.collapsed)::after,
.faq .accordion-button::after {
        width: 40px;
        height: 40px;
        text-align: center;
        font-weight: 700;
        font-size: 35px;
        color: #fff;
        line-height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
}

.faq .accordion-button::after {
    background: #FF9300;
    content: "+";
}

.faq .accordion-button:not(.collapsed)::after {
    background: var(--text-purple);
    content: "-";
}

.accordion-collapse {
    width: 100%;
}

@media (max-width:767px){
    .accordion-button .faq__icon{
    width: 100%;
    margin-bottom: 15px;
    }

    .accordion-button .faq__label{
        width: -moz-calc(100% - 50px);
        width: -webkit-calc(100% - 50px);
        width: calc(100% - 50px);
        padding-left: 0;
        padding-right: 0;
    }

    .faq .accordion-button:not(.collapsed)::after,
.faq .accordion-button::after {
  bottom: 0;
  font-size: 30px;
	width: 35px;
	height: 35px;
	line-height: 35px;
}

}
