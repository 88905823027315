/* podcast page */

.loader{
    position: fixed;
    top: 50%;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: url(../../../../assets/images/podcast-bg.jpg);
    background-size:cover; 
    z-index: 9;
}

.loader img{
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    display: block;
}

.loading-spinner span{
    width: 10px;
    height:10px;
    background: #fff;
    border-radius: 10px;
    margin: 0 3px;
    display: inline-block;
}

.podcast-hero{
    padding-top: 80px !important;     
    background: url(../../../../assets/images/podcast-bg.jpg);
    background-size:cover; 
}

.perspective{
    perspective: 3000px;
}

.latest-episode{
    animation: swing 1.5s ease-in-out infinite;
    animation-direction: alternate;
}

@keyframes swing {
    0%{
        transform:rotateX(0deg);
        transform-origin: top;
    }

    100%{
        transform:rotateX(-10deg);
        transform-origin: top;
    }
    
}

.latest-episode__box {
    padding: 25px 20px;
    width: 300px;
    background-color: #39abdf;
    margin-top: -35px;
}

.hanging{
    max-width: 250px;
    display: block;
    margin: 0 auto 20px auto;
}

.music-icon{
    mix-blend-mode: multiply;
}
.latest-episode__heading{
    color:#343180;
}

.latest-episode__box .h4{
    font-size: 1.875rem !important;
}

.latest-episode-content{
    width: calc(100% - 300px);
    padding-left: 34px;
    padding-top: 50px;
}


.podcast-header{
    height: 220px;
    position: relative;
}

.logo-animation{
    position: relative;
}

.logo-animation img{
    position: absolute;
    left: 0;
    right: 0px;
    max-width: 250px;    
}

.logo-animation img:nth-child(2){
    opacity: 0;
    animation: blink .8s ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes blink {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.podcast-tagline {
    position: absolute;
    top: 138px;
    left: 170px;
}

.podcast-tagline img{
    max-width: 300px;
}

.read-more span{
    width: 18px;
    height: 18px;
    margin-left: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    line-height: 17px;
    border-radius: 50%;
    background: #FF9300;
    display: inline-block;
}

.Play-btn, .back-btn {
    min-width: 180px;
    text-align: left;
    padding-left: 50px;
    font-weight: normal !important;
}

.Play-btn svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
}
a.btn.back-btn {
    min-width: 120px;
    padding-left: 20px;
}

.podcast__listing{
    margin-top: 60px;
    position: relative;
    transition: all .5s ease-in-out;
    transform: translateY(0);
}

.podcast__listing a{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
}

.podcast__listing__inner{
    height: 300px;
    padding: 40px;
    position: relative;
}

.podcast-bg-first{
    background: url(../../../../assets/images/podcast-1.jpg);
    background-size:cover;
}
.podcast-bg-second{
    background: url(../../../../assets/images/podcast-2.jpg);
    background-size:cover;
}

.podcast-bg-third{
    background: url(../../../../assets/images/podcast-3.jpg);
    background-size:cover;
}
.podcast__listing .podcast__listing__inner h2.h4 {
	font-size: 1.5rem !important;
	line-height: 1.875rem !important;
}

.podcast-tagline .pct-dkp-mob {
    display: none;
}

.podcast-spacer{
    padding-top: 150px !important;
}

.mob-podcast-header{
 display: none;   
}

@media (max-width:991px){
    .podcast__listing__inner{
        height: auto;
        min-height: 200px;
    }
    .podcast__listing__inner {
        padding: 20px;
    }
}

@media (max-width:850px){
    .podcast-tagline img {
        max-width: 200px;
    }
}

@media (max-width:767px){
    .mob-podcast-header{
        display: block !important;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 12px; 
       }
       .logo-animation img {
        position: absolute;
        left: -60px;
        right: 0px;
        max-width: 113px;
        margin: 0 auto;
    }

    .podcast-tagline {
        position: absolute;
        top: 70px;
        right: 70px;
        left: inherit;
    }
    
    .podcast-tagline .pct-dkp-mob {
        display: block;
        width: 100%;
        max-width: 90px;
        margin-left: auto;
    }

.latest-episode {
    margin: 0 auto;
}
.latest-episode-content{
    width: 100%;
    padding-left: 0;
}
/* .podcast-header {
    max-width: 380px;
    margin: 0 auto;
} */
.podcast-tagline .pct-dkp-img {
    display: none;
}
/* .podcast-tagline .pct-dkp-mob {
    display: block;
    width: 100%;
} */

}

.podcast-date-duration{
    font-size: 14px;
    color: #E4E8F0;
}