.opacity0,
.about-hero__subheading {
  opacity: 0;
}


.faq-item-spacer .accordion-header{
  position: relative;
}

.faq-item-spacer .accordion-item{
padding-top: 0;
padding-bottom: 0;
}

.faq-item-spacer .accordion-button {
	padding-top: 35px !important;
	padding-bottom: 28px !important;
	position: static;
}

.faq-item-spacer .faq__label{
  padding-left: 0;
}

.faq-item-spacer .accordion-button:not(.collapsed)::after, .faq-item-spacer .accordion-button::after {
	top: 25px;
}

.decimal-list{
  list-style: decimal !important;
  padding-left: 15px !important;
}

/* HOME PAGE ACCORDIAN */

 .faq .home-accordian .accordion-button{
  pointer-events: none;
}
 .faq .home-accordian .accordion-button::after {
	display: none !important;
}

@media (max-width: 991px) {
  .about-hero h1 {
    margin-bottom: 50px !important;
  }
}

@media (max-width: 767px) {
  body .heared-listing ul.h4 {
    font-size: 1.25rem !important;
    line-height: 1.875rem !important;
  }
}
