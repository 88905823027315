.steps {
    /* margin-top: 400px; */
    /* padding-top: 100px; */
}

.steps__content {
    max-width: 537px;
    width: 100%;
    position: absolute;
}

.steps__heading {
    font-size: 1.125rem;
}

.steps__subheading {
    fill: #808CE3;
    font-size: 1rem;
}

.steps__one__graphics {
    position: relative;
}

#Scroll_Group_1 {
    stroke-opacity: 0.5;
    stroke-dasharray: 3500;
    stroke-dashoffset: 3500;
}

@media (min-width:768px){
    .mob-steps{
      display: none;
   }
 }


 @media (max-width:767px){
    .desktop-steps{
      display: none;
   }
 }
