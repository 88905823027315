/* general style */

:root {
    --pt-150: 170px;
}

.spacer-t {
    padding-top: var(--pt-150);
}

.spacer-b {
    padding-bottom: var(--pt-150);
}

.spacer-both {
    padding: var(--pt-150) 0px;
}

/* how to use  section */

.symptomes-categories {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 30px;
}

.symptomes-categories span {
    display: block;
    font-size: 1.5rem;
    text-transform: capitalize;
    margin-bottom: 10px;
}

/* how to record section */

@media (min-width:768px) {

    .how-to-record .image-section img,
    .instruction-image-section img,
    .how-to-use-chart-image img {
        position: sticky;
        top: 100px;
    }
}

.record-syptoms-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 19px;
}

.record-syptoms__items img {
    display: block;
    margin: 0 auto 15px auto;
}

.record-syptoms__items span {
    display: block;
    color: #808CE3;
    text-align: center;
    font-size: 14px;
}


/* Application use section */


.checkbox-wrapper {
    pointer-events: none;
}

.checkbox-fields {
    color: #D1D7FF;
    margin-bottom: 16px;
}

.checkbox-fields input {
    display: none;
}

.checkbox-fields label {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    background-color: #fff;
    vertical-align: middle;
    color: #D1D7FF;
    margin-right: 15px;
    cursor: pointer;
}

.checkbox-fields input:checked + label {
    background: #448EF7 url('../../../../assets/images/Check.svg');
    background-position: center center;
    background-repeat: no-repeat;
}

.checkbox-wrapper textarea {
    border-radius: 3px;
    border: none;
    padding: 5px;
    color: #000000;
    font-size: 1rem;
    resize: none;
    max-width: 350px;
    width: 100%;
    margin-bottom: 20px;
}


/* slider section */

.result-slider img {
    display: block;
    margin: 0 auto;
}

.result-slider__items {
    padding: 0 65px;
}

/* chart section */

.circle {
    display: inline-flex;
    gap: 25px;
    position: relative;
    padding: 0 3px;
}

.circle-blk__shape {
    display: block;
    width: 36px;
    height: 36px;
    margin: 0 auto 10px auto;
    border-radius: 50px;
    position: relative;
}

.circle-blk__shape::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    background: #03143B;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.circle::before {
    background: transparent;
    position: absolute;
    top: 0;
    width: calc(100% - 3px);
    height: 40px;
    content: "";
    border: 1px solid #645BE6;
    border-radius: 50px;
    left: 0;
    top: -2px;
}

.circle span {
    font-size: 16px;
    font-weight: 400;
}

/* navigator section */

.section-navigator__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
}

.section-navigator__items svg {
    display: block;
    margin: 0 auto 15px auto;
}

.section-navigator__items a:hover svg path {
    fill: var(--text-primary-color);
}

.section-navigator__items a:hover span {
    color: var(--text-primary-color);
}


/* library section */


.library-grid {
    display: inline-grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-items: center;
}

.library-grid__blk {
    width: 140px;
    height: 140px;
    background: #eee;
    border-radius: 50%;
    text-align: center;
    display: grid;
    place-items: center;
    position: relative;
}

/* .library-grid > div:last-child {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: -10px;
} */

.library-grid__blk span {
    color: #645BE6;
    font-size: 14px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.library-grid__first {
    background: transparent radial-gradient(closest-side at 16% 17%, #FFFFFF 0%, #C9D0FF 100%) 0% 0% no-repeat padding-box;
}

.library-grid__second {
    background: transparent radial-gradient(closest-side at 20% 12%, #FFFFFF 0%, #D9BEF8 100%) 0% 0% no-repeat padding-box;
}

.library-grid__third {
    background: transparent radial-gradient(closest-side at 9% 24%, #FFFFFF 0%, #D3EDE5 100%) 0% 0% no-repeat padding-box;
}

.library-grid__fourth {

    background: transparent radial-gradient(closest-side at 11% 23%, #FFFFFF 0%, #AECFEF 100%) 0% 0% no-repeat padding-box;
}

.library-grid__fifth {
    background: transparent radial-gradient(closest-side at 11% 22%, #FFFFFF 0%, #EBDDC6 100%) 0% 0% no-repeat padding-box;

}


/* prep slider */

.prep__blk {
    padding: 0 65px;
}

.prep__blk img {
    display: block;
    margin: 0 auto;
}

.prep .slick-arrow {

    top: calc(50% + 20px);
    -webkit-transform: translate(0, calc(-50% + 20px));
    -moz-transform: translate(0, calc(-50% + 20px));
}

/* slider section */

.steps-slider .slick-slider {
    padding-top: 80px;
}

.steps-slider .slick-dots {
    list-style: none !important;
    position: absolute;
    top: 0px;
}

.steps-slider .slick-dots li {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.steps-slider .slick-dots button {
    width: 35px !important;
    height: 35px !important;
    background: #C12EC1 !important;
    display: block !important;
    border-radius: 50%;
    margin: 0px 15px;
    color: #fff !important;
    font-size: 1rem;
    text-align: center;
    line-height: 24px !important;
    font-weight: bold;
    opacity: 0.5;
}

.steps-slider .slick-dots .slick-active button {
    opacity: 1;
}

.steps-slider__blk {
    padding: 0px 80px;
}


.steps-slider .slick-dots button::before {
    display: none;
}

.steps-slider .slick-arrow {
    top: 110px;
}

.steps-slider .slick-arrow svg,
.prep .slick-arrow svg {
    width: 60px;
    height: 60px;
}

.slick-arrow.slick-disabled {
    opacity: 0.3;
}


/* media query start */

@media (max-width:568px) {
    .steps-slider__blk {
        padding: 0px 50px;
    }

    .steps-slider .slick-dots button {
        margin: 0px;
    }

    .symptomes-categories span {
        font-size: 1rem;
    }

    .section-navigator__wrapper {
        gap: 35px;
    }

    .record-syptoms-wrapper {
        grid-gap: 35px;
    }

    .circle {
        grid-gap: 15px;
    }
}

@media (max-width:374px) {
    .circle{
        grid-gap: 5px;
    }

    .section-navigator__wrapper{
        grid-gap: 20px;
    }
}