.content-grid h2 {
	font-size: 50px !important;
}

.content-grid__blk h3{
font-size: 20px !important;
line-height: 27px !important;
font-weight: 500;
}

.content-grid__blk p {
	margin-bottom: 5px;
	font-size: 13px;
	line-height: 17px;
}

@media (max-width:767px){
	.content-grid h2 {
		font-size: 40px !important;
	}
}