header {
	position: fixed;
    top: 0;
    z-index: 1000;
    overflow: hidden;
    width: 100%;
	height: 86px;
	background: var(--bg-primary) !important;
}
.cookie-wrapper{
    position: absolute !important;
    top: 'none' !important;
    bottom:0 !important;
    z-index:999999999 !important;
}
/*
header .container-sm::before {
    position: absolute;
    top: -250px;
    left: 0px;
    width: 100%;
    height: 500px;
    content: "";
    z-index: -1;
    border-radius: 100%;
    background: -moz-radial-gradient(circle at 50% 50%, rgba(7, 0, 183, 1) 0%, rgba(7, 0, 183, 1) 46%, rgba(3, 20, 59, 1) 83%, rgba(3, 20, 59, 1) 100%);
    background: -webkit-radial-gradient(circle at 50% 50%, rgba(7, 0, 183, 1) 0%, rgba(7, 0, 183, 1) 46%, rgba(3, 20, 59, 1) 83%, rgba(3, 20, 59, 1) 100%);
    background: -o-radial-gradient(circle at 50% 50%, rgba(7, 0, 183, 1) 0%, rgba(7, 0, 183, 1) 46%, rgba(3, 20, 59, 1) 83%, rgba(3, 20, 59, 1) 100%);
    background: -ms-radial-gradient(circle at 50% 50%, rgba(7, 0, 183, 1) 0%, rgba(7, 0, 183, 1) 46%, rgba(3, 20, 59, 1) 83%, rgba(3, 20, 59, 1) 100%);
    background: radial-gradient(circle at 50% 50%, rgba(7, 0, 183, 1) 0%, rgba(7, 0, 183, 1) 46%, rgba(3, 20, 59, 1) 83%, rgba(3, 20, 59, 1) 100%);
}
*/

header .container-sm{
    max-width: 1140px !important;
}

header .container-sm::before {
     background: url('../../../assets/images/header-bg.svg');
     background-size:cover;
     background-repeat: no-repeat;
     z-index: -1;
     width: 100%;
     height: 100%;
     content: "";
     position: absolute;
     top: 0px;
     left: 0px;
     right: 0px;
     z-index: -1;
 }

header .navbar-brand img{
    width: 100%;
    max-width: 300px;
}

header .navbar-nav > li a {
	color: #fff !important;
	transition: all .15s ease-in-out;
	padding: 0 !important;
	font-size: 1rem;
	position: relative;
	overflow: hidden;
	font-size: 24px;
	padding: 18px 50px !important;
	display: block;
    z-index: 1;
    text-align: right;
}

header .navbar-nav > li a span::before{
	position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    width: 80%;
    border-bottom: 2px solid #63BFE3;
    padding-bottom: 5px;
}

header .navbar-nav > li a::before{
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    content:"";
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #63BFE3;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: all .15s linear;
}

header .navbar-nav > li a:hover::before{
    transform: scaleY(1);
    background: #63BFE3 !important;
}

header .navbar-nav > li a.active {
    color: var(--text-purple) !important;
}

header .navbar-nav > li:not(:last-child) {
    margin-right: 38px;
}

.call-action-btn {
    min-width: 200px;
    text-align: center;
    border-radius: 30px;
    padding: 13px 15px !important;
    z-index: 1;
    position: relative;
    background: #88bfe8;
   
    background: rgb(0, 166, 226);
    background: -moz-linear-gradient(left, rgba(0, 166, 226, 1) 0%, rgba(128, 140, 227, 1) 40%, rgba(192, 76, 134, 1) 71%, rgba(235, 93, 45, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(0, 166, 226, 1) 0%, rgba(128, 140, 227, 1) 40%, rgba(192, 76, 134, 1) 71%, rgba(235, 93, 45, 1) 100%);
    background: linear-gradient(to right, rgba(0, 166, 226, 1) 0%, rgba(128, 140, 227, 1) 40%, rgba(192, 76, 134, 1) 71%, rgba(235, 93, 45, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a6e2', endColorstr='#eb5d2d', GradientType=1);
  
}

.desktop__link > a{
    margin-right: 30px;
}

.desktop__link a:hover{
    color: var(--text-purple) !important;
}

/* MOBILE MENU */


.navbar-toggler {
	border: 0;
	padding: 0;
	background: transparent;
	border-radius: 50px 50px 0px 0px;
	width: 60px;
    height: 80px;
    align-self: flex-end;
}

.navbar-toggler:focus{
box-shadow: none;
}

.navbar-toggler span {
	width: 30px;
	height: 3px;
    margin: 0 auto;
	display: block;
	background: #808CE3;
	border-radius: 50px;
    position: relative;
    transition:all .15s linear;
}

.navbar-toggler span::before {
	width: 100%;
	height: 3px;
	position: absolute;
	top: -6px;
	background: #808CE3;
	border-radius: 50px;
	content: "";
    transition:all .15s linear;
    left: 0;
}

.navbar-toggler span::after {
	width: 100%;
	height: 3px;
	position: absolute;
	top: 6px;
	background: #808CE3;
	border-radius: 50px;
	content: "";
    transition:all .15s linear;
    left: 0;
}

.toggle-menu{
    background: #13A5DF;
}
.toggle-menu span::before{
  transform: rotate(45deg);
  background-color: #fff;
  top: 0;
} 

.toggle-menu span{
    background-color: #13A5DF;
}

.toggle-menu span::after{
    transform: rotate(-45deg);
    top: 0;
    background-color: #fff;
  }

 /* humbuger menu */

  /* @media(max-width:991px){ */

    header{
        overflow: visible !important;
    }
    /* header .container{
        padding: 0 !important;
        max-width: 100%;
    } */

    /* header .container-sm::before{
        display: none;
    } */
    header .navbar-brand{
        /* margin-left: 12px; */
        padding-bottom: 0;
        /* max-width: 200px; */
    }

    #navbarNav {
        background: #13A5DF;
        text-align: center;
        /* padding: 55px 20px 20px 20px; */
        position: absolute;
        right: 12px;
        top: 100%;
        max-width: 290px;
        width: 100%;
    }
    
    #navbarNav ul {
        padding: 0px;
    }

    header #navbar-nav .navbar-nav  .nav-item a{
        font-size: 1.25rem !important; 
    }

    header .navbar-nav li {
        margin: 0px !important;
        width: 100%;
    }

    @media (min-width:576px){
    header .navbar-nav li:nth-last-child(2) a span::before{
        border-bottom: none;
    }
    }

    /* header .navbar{
        overflow: hidden;
    } */

  @media(max-width:991px){
    header .container{
        padding: 0 !important;
        max-width: 100%;
    }
    header .navbar-brand{
        margin-left: 12px;
    }
    #navbarNav{
        right: 0px;
    }

      body header{
        padding-top: 0px;    
        background:-moz-radial-gradient(circle at 50% 50%, rgba(7, 0, 183, 1) 6%, rgba(5, 9, 125, 1) 49%, rgba(3, 20, 59, 1) 96%) !important;
        background:-webkit-radial-gradient(circle at 50% 50%, rgba(7, 0, 183, 1) 6%, rgba(5, 9, 125, 1) 49%, rgba(3, 20, 59, 1) 96%) !important;
        background:-o-radial-gradient(circle at 50% 50%, rgba(7, 0, 183, 1) 6%, rgba(5, 9, 125, 1) 49%, rgba(3, 20, 59, 1) 96%) !important;
        background:-ms-radial-gradient(circle at 50% 50%, rgba(7, 0, 183, 1) 6%, rgba(5, 9, 125, 1) 49%, rgba(3, 20, 59, 1) 96%) !important;
        background:radial-gradient(circle at 50% 50%, rgba(7, 0, 183, 1) 6%, rgba(5, 9, 125, 1) 49%, rgba(3, 20, 59, 1) 96%) !important;
    }
     header .container-sm::before{
        display: none;
    }

    header .call-action-btn{
        margin-left: auto;
    }
} 

@media(max-width:767px){

    header #navbarNav{
        max-width: 100%;   
    }

    header .navbar-nav > li a span::before {
        width: 90%;
    }
    header .navbar-nav li:nth-child(4) a span::before {
        border-bottom: 2px solid #63BFE3;
    }

    header .navbar-nav > li a{
        font-size: 1.25rem;
        padding: 10px 50px !important;
    }
 }

  @media(max-width:575px){ 
    .call-action-btn{
       display: none;     
    }
   
    header .navbar-brand img{
        max-width: 200px;
        height: auto;
    } 

   }
