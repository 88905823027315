.container{
	max-width: 1140px;
}

.symptoms-tracking h1 {
	font-size: 20px !important;
	font-weight: bold;
	color: #13a5df;
}

.symptoms-tracking .col-md-5{
		position: absolute;
		right: -250px;
		width: 100%;
		max-width: 800px;
		top: -100px;
}

.symptoms-tracking__content{
    max-width: 400px; 
}

.symptoms-tracking__content p{
	font-size: 20px;
    line-height: 27px;
	font-weight: 500;
}

.symptoms-tracking .store-links img {
	width: 160px;
}

.features-lisitng li {
	position: relative;
	padding-left: 80px;
	padding-top: 18px;
	font-size: 25px;
	font-weight: bold;
	font-family: "Nunito", sans-serif;
	color: #10a2dd;
}

.features-lisitng li::before {
	content:url(../../../../../src/images/check-line-popper.png);
	position: absolute;
	left: 0;
	top: 33%;
    width: 62px;
    height: 62px;
}
.features-lisitng li span {
	display: block;
	font-size: 0.875rem;
	font-weight: normal;
}

.note {
	font-size: 0.875rem;
	max-width: 450px;
	line-height: 20px;
}


@media (max-width:900px){
	.symptoms-tracking .col-md-5 {
		right: -150px;
		max-width: 600px;
	}
}

@media (max-width:767px){

	.symptoms-tracking .col-md-5 {
		position: static !important;
		max-width: 100%; 
	} 

	.symptoms-tracking__content {
		margin: 0 auto;
		text-align: center;
	}

	.store-links{
		justify-content: center;
	}
}