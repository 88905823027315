.home-care-program {
	.inner-wrapper {
		max-width: 650px;
		margin: 0 auto;
		text-align: center;
		z-index: 0;

		&::before {
			width: 100%;
			height: 750px;
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			background: transparent url('../../../../images/images/hurricane-icon.png') no-repeat top center;
			background-size: auto 775px;
			z-index: 0;
			opacity: 0.75;

		}
	}


	&.content-grid {
		h1 {
			font-size: 20px !important;
			font-weight: bold;
			color: #13a5df;
		}

		h2 {
			font-size: 50px !important;
			line-height: 61px !important;
			color: #FFFFFF;
			font-weight: normal;
		}

		h3 {
			font-size: 30px !important;
			line-height: 33px !important;
			color: #68CBF2;
			font-weight: normal;
		}

		p {
			font-size: 20px !important;
			line-height: 28px !important;
			color: #FFFFFF;
			font-weight: normal;
		}

		a.btn {
			max-width: 360px;
			margin: 0 auto;
		}
	}


}