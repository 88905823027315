footer {
    background-color: #000000;
    padding: 80px 0;
}

.footer__content.mt-5 p {
    color: #BCBCBC;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
}

.footer__content a {
    color: var(--text-secondary-color);
}

.social-links {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.social-links a {
    position: relative;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    text-align: center;
    line-height: 37px;
    overflow: hidden;
    margin: 0 13px;
    background: #13A5DF;
    transition: all .15s linear;
}

.social-links a::before {
    background: #000;
    width: 95%;
    height: 95%;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
}

.social-links a:hover {
    background: -moz-linear-gradient(left, rgba(17, 164, 222, 0.7) 0%, rgba(29, 157, 217, 0.75) 17%, rgba(55, 140, 203, 0.77) 23%, rgba(75, 117, 185, 0.81) 36%, rgba(103, 94, 168, 0.84) 47%, rgba(114, 91, 166, 0.87) 56%, rgba(161, 80, 158, 0.89) 64%, rgba(211, 76, 114, 0.94) 79%, rgba(219, 81, 88, 0.95) 84%, rgba(238, 97, 36, 0.98) 92%, rgba(238, 97, 36, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(17, 164, 222, 0.7) 0%, rgba(29, 157, 217, 0.75) 17%, rgba(55, 140, 203, 0.77) 23%, rgba(75, 117, 185, 0.81) 36%, rgba(103, 94, 168, 0.84) 47%, rgba(114, 91, 166, 0.87) 56%, rgba(161, 80, 158, 0.89) 64%, rgba(211, 76, 114, 0.94) 79%, rgba(219, 81, 88, 0.95) 84%, rgba(238, 97, 36, 0.98) 92%, rgba(238, 97, 36, 1) 100%);
    background: linear-gradient(to right, rgba(17, 164, 222, 0.7) 0%, rgba(29, 157, 217, 0.75) 17%, rgba(55, 140, 203, 0.77) 23%, rgba(75, 117, 185, 0.81) 36%, rgba(103, 94, 168, 0.84) 47%, rgba(114, 91, 166, 0.87) 56%, rgba(161, 80, 158, 0.89) 64%, rgba(211, 76, 114, 0.94) 79%, rgba(219, 81, 88, 0.95) 84%, rgba(238, 97, 36, 0.98) 92%, rgba(238, 97, 36, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b311a4de', endColorstr='#ee6124', GradientType=1);
}

.social-links svg {
    position: relative;
}

.social-links a:hover .social-media-icon {
    fill: url(#linear-gradient);
}


  .icon-position{
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }

  .icon-style{
    border-radius: 50%;
    height: 30px;
    width: 30px;
    color: #fff;
    cursor: pointer;
    transition: all .15s linear;
  }
  .icon-style:hover{
    background-color: #551B54;
  }