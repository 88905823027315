.content-sec {
    position: relative;
  /*   padding: 150px 0 400px 0; */
    overflow: hidden;
}

.content-sec__animate {
    position: absolute;
    left: 0;
   right: 0; 
   margin: 0 auto;
   max-width: 991px;
   opacity: 0;
}

@media (max-width:767px) {

    /* #noneoftrue{
        padding-top: 200px;
        padding-bottom: 200px;
    } */
}

@media (max-width:567px) {
    #steps +  .content-sec h4{
        font-size: 1.25rem !important;
    }
}
