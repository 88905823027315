.care-program {
    width: 100vw;
    min-height: 1200px;
    background: transparent radial-gradient(closest-side at 50% 5%, #F8FBFF 0%, #BFDCFB 100%) 0% 0% no-repeat padding-box;
    position: relative;
    overflow-x: hidden;

    .ball-floating {
        position: absolute;
        z-index: 1;

        &::before {
            content: "";
            top: 0px;
            left: 0px;
            position: absolute;
            background-size: cover;
            z-index: 0;
            opacity: 1;
        }

        &.dot-y-1 {
            right: 16%;
            top: 600px;

            &::before {
                width: 262px;
                height: 262px;
                background: transparent url('../../../../images/images/dot-y-1.svg') no-repeat top left;
            }
        }

        &.dot-p-1 {
            left: 10%;
            top: 713px;

            &::before {
                width: 46px;
                height: 46px;
                background: transparent url('../../../../images/images/dot-p.svg') no-repeat top left;
            }
        }

        &.dot-b-1 {
            left: calc(100vw / 10 - 150px);
            top: 742px;

            &::before {
                width: 150px;
                height: 150px;
                background: transparent url('../../../../images/images/dot-b.svg') no-repeat top left;
            }
        }

        &.dot-p-2-sp {
            right: 7%;
            top: 845px;

            &::before {
                width: 70px;
                height: 70px;
                background: transparent linear-gradient(90deg, #6D5AA7 0%, #7858A5 18%, #A24D9D 54%, #E54170 100%) 0% 0% no-repeat padding-box;
                opacity: 0.79;
                border-radius: 50%;
            }
        }

        &.dot-w-1 {
            left: calc(100vw / 10 - 197px);
            top: 1142px;

            &::before {
                width: 230px;
                height: 230px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                opacity: 0.36;
                border-radius: 50%;
            }
        }

        &.dot-y-2 {
            right: 11%;
            top: 1819px;

            &::before {
                width: 73px;
                height: 73px;
                background: transparent url('../../../../images/images/dot-y-2.svg') no-repeat top left;
            }
        }

        &.dot-p-3 {
            left: calc(100vw / 10 - 39px);
            top: 1845px;

            &::before {
                width: 46px;
                height: 46px;
                background: transparent url('../../../../images/images/dot-p.svg') no-repeat top left;
            }
        }

        &.dot-w-2 {
            left: calc(100vw / 10 - 139px);
            top: 1868px;

            &::before {
                width: 135px;
                height: 135px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                opacity: 0.36;
                border-radius: 50%;
            }
        }

        &.dot-p-4 {
            right: 5%;
            top: 3000px;

            &::before {
                width: 46px;
                height: 46px;
                background: transparent url('../../../../images/images/dot-p.svg') no-repeat top left;
            }
        }

        &.dot-b-2 {
            right: 6%;
            top: 3000px;

            &::before {
                width: 150px;
                height: 150px;
                background: transparent url('../../../../images/images/dot-b.svg') no-repeat top left;
            }
        }

        &.dot-y-3 {
            left: calc(100vw / 10 - 220px);
            top: 3500px;

            &::before {
                width: 262px;
                height: 262px;
                background: transparent url('../../../../images/images/dot-y-1.svg') no-repeat top left;
            }
        }

        &.dot-p-5 {
            right: 9%;
            top: 5750px;

            &::before {
                width: 74px;
                height: 74px;
                background: transparent url('../../../../images/images/dot-p.svg') no-repeat top left;
                background-size: cover;
            }
        }

        &.dot-w-3 {
            left: calc(100vw / 10 - 180px);
            top: 6000px;

            &::before {
                width: 230px;
                height: 230px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                opacity: 0.36;
                border-radius: 50%;
            }
        }

        &.dot-y-4 {
            right: 11%;
            top: 6600px;

            &::before {
                width: 73px;
                height: 73px;
                background: transparent url('../../../../images/images/dot-y-2.svg') no-repeat top left;
            }
        }

        &.dot-w-4 {
            right: 19%;
            top: 6636px;
            z-index: 0;

            &::before {
                width: 135px;
                height: 135px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                opacity: 0.36;
                border-radius: 50%;
            }
        }

    }

    .inner-wrapper {
        &::after {
            width: 100%;
            height: -webkit-fill-available;
            position: absolute;
            top: 0px;
            right: 0;
            content: "";
            background: transparent url('../../../../images/images/hearts-blue.png') no-repeat top right;
            background-size: cover;
            z-index: 0;
            opacity: 1;
            background-position-x: calc(100vw - 500px);
        }

        .container {
            z-index: 1;
            position: relative;
            text-align: center;
            max-width: 830px;



            h1 {
                font-size: 46px !important;
                font-weight: normal;
                color: #1147bf;
                font-weight: 600;
            }

            h2 {
                font-size: 34px !important;
                line-height: 38px !important;
                color: #1147bf;
                font-weight: normal;
            }

            h3 {
                font-size: 42px !important;
                line-height: 46px !important;
                color: #1147bf;
                font-weight: normal;
                margin-top: 60px !important;
            }

            h4 {
                font-size: 28px !important;
                line-height: 36px !important;
                color: #975abe;
                font-weight: normal;
                max-width: 378px;
                margin: 0 auto;
            }

            p,
            ul li,
            .accordion-body {
                font-size: 20px !important;
                line-height: 28px !important;
                color: #212731;
                font-weight: normal;

                a {
                    text-decoration: underline !important;
                    color: inherit;

                    &.btn {
                        color: #fff;
                        text-decoration: none !important;
                    }
                }

                &.standout {
                    color: #C12EC1;
                }
            }

            li {
                padding: .5rem 0;
            }

            .footnotes {

                p {
                    text-align: left !important;
                    font-size: 1rem !important;
                    line-height: 1.5;
                }
            }

            .care-steps {
                max-width: 600px;
                margin: 0 auto;
                text-align: left;

                p {
                    &.signup-step {
                        font-weight: bold;
                        text-align: left;
                    }

                    &.icon {
                        text-align: center;

                        img {
                            max-width: 240px;
                        }
                    }

                    &.cta {
                        text-align: center;
                    }
                }

                &.in-hundred-days {
                    h4 {
                        margin: unset;
                    }
                }

                .issue-list {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }

                .issue-list li {
                    display: flex;
                    align-items: flex-start;
                    gap: 0.5rem;
                    line-height: 1.5;
                }

                .issue-list li::before {
                    content: attr(data-icon);
                    display: inline-block;
                    margin-right: 0.5rem;
                }

            }

            .accordion-button {
                font-size: 20px !important;
                color: #7D00CC !important;
                font-weight: normal;
                padding: 1.5rem 0 !important;
                border-bottom: 1px solid;

                &::after {
                    background: transparent !important;
                    content: "";
                    transform: rotate(45deg) !important;
                    margin-left: .5rem !important;
                    color: #212731 !important;
                    font-weight: 100;
                    border: 2px solid #707070;
                    border-top: none;
                    border-left: none;
                    border-radius: 0;
                    width: 15px;
                    height: 15px;
                    top: 1.5rem;
                    right: 5px;
                }

                &[aria-expanded="true"] {
                    border-bottom: none;

                    &::after {
                        transform: rotate(-135deg) !important;
                    }
                }
            }

            .accordion-body {
                text-align: left;
                padding: 2rem;
            }

        }
    }
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.fw-semibold {
    font-weight: 900 !important;
    font-size: 1.4rem !important;
}

.animations {
    font-family: 'Isidora Sans';
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0;
    background: white url('../../../../images/images/hearts-blue.png') no-repeat top right;
    background-size: cover;
    background-position-x: calc(100vw - 500px);
    z-index: 2;
    text-align: center;
    padding: 2rem;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0;

    .animation-item {
        /* display: flex; */
        justify-content: center;
        align-items: center;
        /* height: 15vh; */
        width: auto;
        padding: 0;

        &:first-child img {
            margin-top: 10rem;
        }

        img {
            width: auto;
            height: calc(100vw / 7);
            object-fit: contain;
        }
    }

    @media (max-width: 767px) {
        padding: 1rem;
        gap: 0rem;

        .animation-item img {
            max-width: 100%;
        }
    }

    @media (min-width: 768px) {
        .animation-item {
            &:first-child img {
                margin-top: 1rem;
                max-height: calc(100vh / 5);
            }

            &:nth-child(2) img {
                margin-top: 0rem;
                max-height: calc(100vh / 10);
            }

            &:nth-child(3) img {
                margin-top: 0rem;
                max-height: calc(100vh / 5);
            }

            &:nth-child(4) img {
                margin-top: 0rem;
                max-height: calc(100vh / 10);
            }

            &:nth-child(5) img {
                margin-top: 0rem;
                max-height: calc(100vh / 11);
            }

            &:nth-child(6) img {
                margin-top: 0rem;
                max-height: calc(100vh / 6);
            }

            img {
                max-width: 90%;
            }
        }


    }
}