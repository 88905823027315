.alphabat-listing{
    list-style-type: lower-alpha;
}
.alphabat-listing li{
    margin-bottom: 5px;
}

.roman-listing {
	list-style-type: lower-roman;
    padding-left: 15px;
}